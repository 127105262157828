<template>
  <div class="container" style="height: 100vh">
    <div class="my_top" ref="myTop">
      <div class="my_top_left" v-if="isLogin" ref="myLeft1">
        <img src="../assets/a.png" class="my_top_image" alt="" />
        <div class="my_top_text">
          <div>
            <span>{{ CYBoxUser.loginName }}</span>
            <span style="padding-left: 4px;">
              <img src="../assets/yueka.png" class="my_vip" v-if="yueka" alt="">
              <img src="../assets/jika.png" class="my_vip" v-if="jika" alt="">
              <img src="../assets/nianka.png" class="my_vip" v-if="nianka" alt="">
              <img src="../assets/xiaon.png" class="my_vip" v-if="xiaon" alt="">
              <img src="../assets/xinchun.png" class="my_vip" v-if="xinchun" alt="">
            </span>
          </div>
          <span style="font-size: 12px">Tel : {{ pNum }}</span>
        </div>
      </div>
      <div class="my_top_left" ref="myLeft2" v-else>
        <img src="../assets/i.png" class="my_top_image" alt="" />
        <div class="my_top_text">
          <div class="my_click" @click="onclick('login')">登录/注册</div>
        </div>
      </div>
      <div style="display: flex;position: absolute;right: 2%;">
        <div
          class="my_out"
          style="margin-right: 12px"
          v-if="isMore"
          ref="myMore"
          @click="clickHandle('switch')"
        >
          切换
        </div>
        <div
          class="my_out"
          v-if="isLogin"
          ref="myOut"
          @click="clickHandle('signout')"
        >
          退出
        </div>
      </div>
    </div>
    <div class="my_center">
      <div class="my_balance" v-if="isLogin">
        <div class="my_balance_content">
          <img src="../assets/ye.png" class="my_balance_bg" alt="">
          <div class="my_balance_txt">
            <span>糖豆</span>
            <img src="../assets/w.png" class="my_balance_w" @click="goExplain" alt="">
            <span>:</span>
            <span class="my_balance_ye">{{coinSum}}<img v-if="lockState" @click="lockClick"  src="../assets/lock.png" class="my_lock" alt=""></span>
          </div>
          <div class="pay_btn" @click="goRecharge">
            <span class="pay_txt">充值</span>
          </div>
          <div>
            <div class="my_card_s">
              <div class="my_cardview" v-for="(item,index) in cardInfo" :key="item.id"
                @click="selectCard(index)"
                :style="currentCard == index ? 'background:linear-gradient(180deg,#fdc57d 45%,#d9975f 100%);color:#fff;' : 'background: #cfccc7;color:#f3f1ef' "
              >
                <div class="my_balance_card">{{item.name}}</div>
              </div>
            </div>
            <div class="my_card_rmb">
              <!-- <span class="my_txt_time">有效期剩余: 2天</span> -->
              <span class="my_txt_des" v-if="section">{{section}}</span>
              <span class="my_txt_savemoney" v-if="savemoney">
                可省:￥<span class="my_txt_big">{{savemoney}}</span> 元
              </span>
            </div>
            <div class="my_card_banner" v-if="savemoney" @click="gopay">立即购买省钱卡 > </div>
          </div>

          <div class="my_card_l" @click="createClick(dayGetCoin)" v-if="dayGetCoin">
            <span class="my_card_txt">今日可领取{{dayGetCoin}}个糖豆</span>
          </div>
        </div>
      </div>
      <div class="my_center_top" v-if="isLogin">
        <div class="my_played" @click="goplayed" style="background:#eef6f8">
          <img src="../assets/played.png" class="my_played_img" alt="">
          <span class="my_played_txt">我玩过的</span>
        </div>
        <div class="my_played" @click="gopay" style="background:#fcf3f4">
          <img src="../assets/coupon.png" class="my_played_img" alt="">
          <span class="my_played_txt">购买省钱卡</span>
        </div>
        <div class="my_played" @click="goDaySign" style="background:#fefaf1">
          <img src="../assets/day.png" class="my_played_img" alt="">
          <span class="my_played_txt">每日签到</span>
        </div>
      </div>
      <div class="my_center_top" v-if="isLogin">
        <div class="my_played" @click="goInvitation" style="background:#e8fbff">
          <img src="../assets/yaoqing.png" class="my_played_img" alt="">
          <span class="my_played_txt">我的邀请码</span>
        </div>
        <div class="my_played" @click="goReward" style="background:#f3f3ff">
          <img src="../assets/reward.png" class="my_played_img" alt="">
          <span class="my_played_txt">奖励详情</span>
        </div>
        <div class="my_played" @click="goCons" style="background:#ebf3ff">
          <img src="../assets/cons.png" class="my_played_img" alt="">
          <span class="my_played_txt">消费记录</span>
        </div>
      </div>
      <div class="my_center_top" v-if="isLogin">
        <div class="my_played" @click="goNewUser" style="background:#f5ecff" v-show="newUserState == 1">
          <img src="../assets/vip.png" class="my_played_img" alt="">
          <span class="my_played_txt">新用户专享</span>
        </div>
      </div>
      <div class="my_center_btm">
        <div
          class="my_center_item"
          v-for="item in lists"
          :key="item.id"
          @click="onclick(item.id)"
          v-show="CYBoxUser ? true : !item.islogin"
        >
          <div class="my_center_bg">
            <img :src="item.icon" class="my_center_icon" alt="" />
          </div>
          <span>{{ item.name }}</span>
        </div>
      </div>
    </div>

    <!-- 登录 -->
    <van-action-sheet
      v-model="admin.login"
      title="用户登录"
      safe-area-inset-bottom
    >
      <div class="my_content">
        <van-field
          class="my_field"
          label="用户名"
          v-model="form.username"
          @click-right-icon="showPicker = true"
          right-icon="arrow-down"
          placeholder="用户名/手机号"
        />
        <van-field
          class="my_field"
          label="密码"
          :type="ps.s1"
          @click-right-icon="clickIcon('s1')"
          right-icon="closed-eye"
          v-model="form.password"
          placeholder="密码6-12位字母或数字组合"
        />
        <div class="my_saveps">
          <van-checkbox v-model="checked_xieyi" icon-size="16px">
            我已经同意<span style="color:#ff5659" @click="isAgreement()">用户协议</span>
          </van-checkbox>
          <van-checkbox v-model="checked" icon-size="16px"
            >记住密码</van-checkbox
          >
        </div>
        <div class="my_save_btn my_login_btn" @click="clickHandle('login')">
          立即登录
        </div>
        <div class="my_other">
          <span @click="onclick('retrieve')">找回密码</span>
          <span @click="onclick('register')">新用户注册</span>
        </div>
      </div>
    </van-action-sheet>
    <!-- 手机注册 -->
    <van-action-sheet
      v-model="admin.register"
      title="手机号注册"
      safe-area-inset-bottom
    >
      <div class="my_content">
        <van-field
          class="my_field"
          label="手机号"
          v-model="form.phone"
          placeholder="输入手机号"
        />
        <div class="my_code">
          <van-field
            class="my_field"
            label="验证码"
            v-model="form.code"
            placeholder="验证码"
          />
          <div
            class="my_getcode"
            @click="clickHandle('getcode', 'c4')"
            ref="c4"
          >
            获取验证码
          </div>
        </div>
        <div class="my_saveps">
          <van-checkbox v-model="checked_xieyi" icon-size="16px">
            我已经同意<span style="color:#ff5659" @click="isAgreement()">用户协议</span>
          </van-checkbox>
          <van-checkbox v-model="checked" icon-size="16px"
            >记住密码</van-checkbox
          >
        </div>
        <div
          class="my_save_btn my_login_btn"
          @click="clickHandle('phoneregister')"
        >
          注册并登录
        </div>
        <div class="my_other">
          <span></span>
          <span @click="onclick('newuser')">用户名注册</span>
        </div>
      </div>
    </van-action-sheet>
    <!-- 用户名注册 -->
    <van-action-sheet
      v-model="admin.newuser"
      title="用户名注册"
      safe-area-inset-bottom
    >
      <div class="my_content">
        <van-field
          class="my_field"
          label="用户名"
          v-model="form.username"
          placeholder="用户名6-10位字母或数字"
        />
        <van-field
          class="my_field"
          label="密码"
          :type="ps.s4"
          @click-right-icon="clickIcon('s4')"
          right-icon="closed-eye"
          v-model="form.password"
          placeholder="密码6-12位字母或数字组合"
        />
        <div class="my_saveps">
          <van-checkbox v-model="checked_xieyi" icon-size="16px">
            我已经同意<span style="color:#ff5659" @click="isAgreement()">用户协议</span>
          </van-checkbox>
          <van-checkbox v-model="checked" icon-size="16px"
            >记住密码</van-checkbox
          >
        </div>
        <div
          class="my_save_btn my_login_btn"
          @click="clickHandle('usernameregister')"
        >
          注册并登录
        </div>
      </div>
    </van-action-sheet>
    <!-- 找回密码 -->
    <van-action-sheet
      v-model="admin.retrieve"
      title="找回密码"
      safe-area-inset-bottom
    >
      <div class="my_content">
        <van-field
          class="my_field"
          label="手机号"
          v-model="form.phone"
          placeholder="输入手机号"
        />
        <div class="my_code">
          <van-field
            class="my_field"
            label="验证码"
            v-model="form.code"
            placeholder="验证码"
          />
          <div
            class="my_getcode"
            @click="clickHandle('getcode', 'c3')"
            ref="c3"
          >
            获取验证码
          </div>
        </div>
        <van-field
          class="my_field"
          label="新密码"
          :type="ps.s2"
          @click-right-icon="clickIcon('s2')"
          right-icon="closed-eye"
          v-model="form.password"
          placeholder="密码6-12位字母或数字组合"
        />
        <van-field
          class="my_field"
          label="确认密码"
          :type="ps.s3"
          @click-right-icon="clickIcon('s3')"
          right-icon="closed-eye"
          v-model="form.confirmpassword"
          placeholder="密码6-12位字母或数字组合"
        />
        <div
          class="my_save_btn my_login_btn"
          @click="clickHandle('findpassword')"
        >
          重置密码
        </div>
      </div>
    </van-action-sheet>

    <!-- 修改用户名 -->
    <van-action-sheet
      v-model="admin.username"
      title="修改用户名"
      safe-area-inset-bottom
    >
      <div class="my_content">
        <van-field v-model="form.username" placeholder="请输入用户名" />
        <div class="my_remarks my_field">用户名6-10个字母或数字</div>
        <van-field
          v-model="form.password"
          :type="ps.s6"
          right-icon="closed-eye"
          @click-right-icon="clickIcon('s6')"
          placeholder="请输入密码"
        />
        <div class="my_save_btn" @click="clickHandle('modifyusername')">
          保存
        </div>
      </div>
    </van-action-sheet>
    <!-- 修改密码 -->
    <van-action-sheet
      v-model="admin.password"
      title="修改密码"
      safe-area-inset-bottom
    >
      <div class="my_content">
        <van-field
          class="my_field"
          label="旧密码"
          :type="ps.s2"
          right-icon="closed-eye"
          @click-right-icon="clickIcon('s2')"
          v-model="form.password"
          placeholder="6-12位英文或数字"
        />
        <van-field
          class="my_field"
          label="新密码"
          :type="ps.s3"
          right-icon="closed-eye"
          @click-right-icon="clickIcon('s3')"
          v-model="form.newpassword"
          placeholder="6-12位英文或数字"
        />
        <van-field
          class="my_field"
          label="确认密码"
          :type="ps.s4"
          right-icon="closed-eye"
          @click-right-icon="clickIcon('s4')"
          v-model="form.confirmpassword"
          placeholder="6-12位英文或数字"
        />
        <div class="my_save_btn" @click="clickHandle('modifypassword')">
          保存
        </div>
      </div>
    </van-action-sheet>
    <!-- 绑定手机号 -->
    <van-action-sheet
      v-model="admin.bind"
      title="绑定手机号"
      safe-area-inset-bottom
    >
      <div class="my_content">
        <van-field
          class="my_field"
          label="密码"
          :type="ps.s5"
          right-icon="closed-eye"
          @click-right-icon="clickIcon('s5')"
          v-model="form.password"
          placeholder="6-12位英文或数字"
        />
        <van-field class="my_field" label="手机号" v-model="form.phone" placeholder="输入手机号" />
        <div class="my_code">
          <van-field class="my_field" label="验证码" v-model="form.code" placeholder="验证码" />
          <div class="my_getcode" @click="clickHandle('getcode', 'c3')" ref="c3" >
            获取验证码
          </div>
        </div>
        <div class="my_save_btn" @click="clickHandle('bindphone')">保存</div>
      </div>
    </van-action-sheet>
    <!-- 换绑手机号 -->
    <van-action-sheet v-model="admin.unbind" title="换绑手机号" safe-area-inset-bottom >
      <div class="my_content">
        <div v-show="adopt == false">
          <van-field
            class="my_field"
            label="原手机号"
            v-model="form.cphone"
            placeholder="输入原手机号"
          />
          <div class="my_code">
            <van-field
              class="my_field"
              label="验证码"
              v-model="form.ccode"
              placeholder="验证码"
            />
            <div
              class="my_getcode"
              @click="clickHandle('getccode', 'c2')"
              ref="c2"
            >
              获取验证码
            </div>
          </div>
          <div class="my_save_btn" @click="clickHandle('updatebindphone')">验证</div>
        </div>

        <div v-show="adopt == true">
          <van-field
            class="my_field"
            label="新手机号"
            v-model="form.phone"
            placeholder="输入手机号"
          />
          <van-field
            class="my_field"
            label="密码"
            :type="ps.s5"
            right-icon="closed-eye"
            @click-right-icon="clickIcon('s5')"
            v-model="form.password"
            placeholder="密码"
          />
          <div class="my_code">
            <van-field
              class="my_field"
              label="验证码"
              v-model="form.code"
              placeholder="验证码"
            />
            <div
              class="my_getcode"
              @click="clickHandle('getcode', 'c1')"
              ref="c1"
            >
              获取验证码
            </div>
          </div>
          <div class="my_save_btn" @click="clickHandle('bindphone_up')">绑定</div>
        </div>
      </div>
    </van-action-sheet>
    <!-- 实名认证 -->
    <van-action-sheet
      v-model="admin.auth"
      title="实名认证"
      safe-area-inset-bottom
    >
      <div class="my_content">
        <van-field
          class="my_field"
          label="姓名"
          v-model="form.name"
          placeholder="输入姓名"
        />
        <van-field
          class="my_field"
          label="身份证号"
          v-model="form.idcard"
          placeholder="输入18位身份证号码"
        />
        <div class="my_save_btn" @click="clickHandle('auth')">保存</div>
      </div>
    </van-action-sheet>
    <!-- 用户协议 -->
    <van-action-sheet
      v-model="admin.agreement"
      title="用户协议"
      safe-area-inset-bottom
    >
      <div class="my_content">
        <p class="my_txt" v-for="item in agreementList" :key="item">
          {{ item }}
        </p>
      </div>
    </van-action-sheet>
    <!-- 使用许可协议 -->
    <van-action-sheet
      v-model="admin.permit"
      title="软件使用许可协议"
      safe-area-inset-bottom
    >
      <div class="my_content">
        <p class="my_txt" v-for="item in permitList" :key="item">{{ item }}</p>
      </div>
    </van-action-sheet>
    <!-- 免责声明 -->
    <van-action-sheet
      v-model="admin.statement"
      title="免责申明和权利人保护指引"
      safe-area-inset-bottom
    >
      <div class="my_content">
        <p class="my_txt" v-for="item in statementList" :key="item">
          {{ item }}
        </p>
      </div>
    </van-action-sheet>
    <!-- 隐私政策 -->
    <van-action-sheet
      v-model="admin.privacy"
      title="隐私政策"
      safe-area-inset-bottom
    >
      <div class="my_content">
        <p class="my_txt" v-for="item in privacyList" :key="item.id">
          {{ item }}
        </p>
      </div>
    </van-action-sheet>
    <!-- 未成年保护 -->
    <van-action-sheet
      v-model="admin.protect"
      title="未成年人家长监护工程"
      safe-area-inset-bottom
    >
      <div class="my_content">
        <p class="my_txt" v-for="item in protectList" :key="item.id">
          {{ item }}
        </p>
      </div>
    </van-action-sheet>
    <!-- 糖豆说明 -->
    <van-action-sheet
      v-model="admin.candy"
      title="糖豆介绍"
      safe-area-inset-bottom
    >
      <div class="my_content">
        <p class="my_txt" v-for="item in candyList" :key="item.id">
          {{ item }}
        </p>
      </div>
    </van-action-sheet>
    <!-- 糖豆签到说明 -->
    <van-action-sheet
      v-model="admin.lock"
      title="糖豆签到说明"
      safe-area-inset-bottom
    >
      <div class="my_content">
        <p class="my_txt" v-for="item in lockList" :key="item.id">
          {{ item }}
        </p>
      </div>
    </van-action-sheet>
    <!-- 账号选择器 -->
    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        value-key="name"
        :cancel-button-text="cancelButtonText"
        item-height="56px"
        @confirm="onConfirm"
        @cancel="pickerCancel"
      >
        <template #option="option">
          <div v-if="isLogin">
            <div v-if="CYBoxUser.loginName == option.loginName">
              <span>{{ option.loginName }}</span>
            </div>
            <div v-else>
              <van-checkbox v-if="setUserList" v-model="option.model" @change="pickerChange(option)">{{ option.loginName }}</van-checkbox>
              <span v-else>{{ option.loginName }}</span>
            </div>
          </div>
          <div v-else>
            <span>{{ option.loginName }}</span>
          </div>
        </template>
      </van-picker>
    </van-popup>

    <!-- -------------------------------------- -->
    <van-action-sheet
      v-model="buymenu"
      title="糖豆充值"
      safe-area-inset-bottom
    >
      <div class="my_content">
        <div class="card_cur" v-if="cardTip.state == '0' " style="font-size:14px;">
          <div>
            <span style="color: #e80600;">【{{cardTip.name}}】</span>
            <span>可</span>
            <span style="color: #e80600;">加送{{cardTip.rechageRatio}}%</span>
            <span>糖豆</span>
          </div>
          <div>
            <div class="card_cur_pay" @click="goCurPay">购买</div>
          </div>
        </div>
        <div class="card_cur" v-if="cardTip.id && cardTip.state != '0' " style="font-size:14px;">
          <div>
            <span>您已拥有</span>
            <span style="color: #e80600;">【{{cardTip.name}}】</span>
            <span>,本次支付</span>
            <span style="color: #e80600;">加送{{cardTip.rechageRatio}}%</span>
            <span>糖豆</span>
          </div>
        </div>
        <div class="card_top">
          <div class="card_i"
            v-for="(item, index) in cards" :key="item.id" 
            @click="selectPay(index)"
            :style="currentSelect == index ? 'background:linear-gradient(90deg,#ff5555 45%,#fe0000 100%);color:#fff;' : '' "
          >
            <span class="card_count">{{item.txt}}</span>
            <span class="card_des">{{item.des}}{{cardTip.id && cardTip.state != '0' ? item.total + item.total / 100 * 15 :item.total}}糖豆</span>
          </div>
        </div>
        <div class="card_center">
          <!-- <div class="card_fs">支付方式</div> -->
          <div class="card_select">
            <van-radio-group v-model="radio">
              <van-cell-group>
                <van-cell title="支付宝" :icon="alipay" clickable @click="radio = 'blue'">
                  <template #right-icon>
                    <van-radio name="blue"/>
                  </template>
                </van-cell>
                <van-cell title="微信" :icon="wpay" clickable @click="radio = 'green'">
                  <template #right-icon>
                    <van-radio name="green"/>
                  </template>
                </van-cell>
              </van-cell-group>
            </van-radio-group>
          </div>
        </div>
        <div class="page_pay" @click="launchPay">立即支付</div>
      </div>
    </van-action-sheet>
    <!-- ------------- -->
    <van-action-sheet
      v-model="accountShow"
      :actions="actions"
      cancel-text="取消"
      description="实名账号信息超过2个,请点击解除其他实名的账号"
      @select="onSelect"
    />
    <!-- ------------- -->
    <Signin v-if="isSignin" :havesign="isHaveSign" v-on:clickclose="isSignin = false" v-on:clickevent="goQian" :duration="duration"></Signin>
    <div class="card_tip" v-if="openTip">
      <img src="../assets/tip.png" class="card_tip_image" alt="" @click="closeTip">
      <div class="card_tip_text">{{numa}}<span style="font-weight:300;font-size:16px;padding-left:2px">颗</span> </div>
    </div>
  </div>
</template>
<script>
import Hot from "@/components/Hot.vue";
import Signin from '@/components/Signin.vue'
import {
  validate,
  birthDate,
} from 'chinese-idcard-checker';
import { mapGetters, mapState, mapActions } from "vuex";
import {
  askLogin,
  askSaveName,
  askSavePass,
  askSavePhone,
  askGetCode,
  askunBindPhone,
  askBindCard,
  askFindPass,
  askPhoneRegister,
  askUserNameRegister,
  askAgreement,
  askPermit,
  askPrivacy,
  askProtect,
  askStatement,
  askUserinfo,
  askLeCoin,
  askRecived,
  askPayWith,
  askSignIndex,
  askDoSign,
  askRealName,
  askconfirmPhone,
  askGetImg,
  askIncard,
  askUncard
} from "@/api";
import { ksort } from "@/utils/ksort";
import { UPDATE_USER } from "@/store";
import { Dialog, Toast } from "vant";

import BImage from "@/assets/bind.png";
import PImage from "@/assets/passwrod.png";
import RImage from "@/assets/renzheng.png";
import TImage from "@/assets/tiaokuan.png";
import UImage from "@/assets/unbind.png";
import SImage from "@/assets/username.png";
import YImage from "@/assets/yinsi.png";
import AImage from "@/assets/agreement.png";
import MImage from "@/assets/statement.png";
import CImage from "@/assets/children.png";
import { Encrypt, Decrypt, timeApi, loadPageVar } from "@/utils/index";

import AliPayImage from '@/assets/alipay.png'
import WechatPayImage from '@/assets/wechatpay.png'

export default {
  name: "My",
  components: {
    Hot,
    Signin
  },
  data() {
    return {
      actions: [],
      accountShow: false,
      setUserList: false,
      cancelButtonText: "管理",
      protectList: [],
      privacyList: [],
      statementList: [],
      permitList: [],
      agreementList: [],
      candyList: ['一、什么是糖豆', '糖豆是乐糖游戏平台的虚拟交易货币。1元人民币=1个糖豆。可通过微信、支付宝充值获得。用户可以使用糖豆在乐糖游戏平台对任意一款游戏进行充值。充值进平台的糖豆不可进行提现、退款操作。糖豆在平台上永久有效。用户可以登录乐糖游戏平台【我的】查看糖豆详情。', '二、如何获得糖豆：', '1、通过微信、支付宝进行充值 ', '2、参加乐糖游戏平台不定时的福利活动 ', '3、购买乐糖游戏平台的省钱卡', '三、糖豆的用途：', '1、可以对乐糖游戏平台的任意一款游戏进行充值', '2、糖豆充值达一定等级者，可享受乐糖游戏平台的专属福利及客服服务', '四、免责条款：', '1、为促进用户养成健康的游戏习惯，糖豆不可转让，仅可在乐糖游戏平台使用', '2、特定活动的糖豆以具体的活动规则为准', '3、乐糖游戏有权对平台的部分糖豆规则进行调整、更改或者终止且无需承担任何责任', '4、如因个人原因平台账户被冻结，您的糖豆将自动消失。乐糖游戏无需承担任何责任。', '5、糖豆规则以公布的最新版为准，最终解释权归乐糖游戏平台所有。'],
      lockList: ['当前糖豆余额处于锁定不可消费状态, 在首次充值糖豆后解锁'],
      pNum: "",
      ps: {
        s1: "password",
        s2: "password",
        s3: "password",
        s4: "password",
        s5: "password",
        s6: "password",
        s7: "password",
      },
      form: {
        username: "", // 用户名
        password: "", // 旧密码
        newpassword: "", // 新密码
        confirmpassword: "", // 确认密码
        phone: "", // 手机号
        code: "", // 验证码
        name: "", // 姓名
        idcard: "", // 身份证号
        cphone: "",
        ccode: ""
      },
      admin: {
        login: false,
        register: false,
        retrieve: false,
        username: false,
        password: false,
        newuser: false,
        bind: false,
        unbind: false,
        auth: false,
        agreement: false,
        permit: false,
        statement: false,
        privacy: false,
        protect: false,
        candy: false,
        lock: false
      },
      lists: [
        {
          name: "修改用户名",
          icon: SImage,
          id: "username",
          islogin: true,
        },
        {
          name: "修改密码",
          icon: PImage,
          id: "password",
          islogin: true,
        },
        {
          name: "绑定手机号",
          icon: BImage,
          id: "bind",
          islogin: true,
        },
        {
          name: "换绑手机号",
          icon: UImage,
          id: "unbind",
          islogin: true,
        },
        {
          name: "实名认证",
          icon: RImage,
          id: "auth",
          islogin: true,
        },
        {
          name: "用户协议",
          icon: TImage,
          id: "agreement",
          islogin: false,
        },
        {
          name: "使用协议",
          icon: AImage,
          id: "permit",
          islogin: false,
        },
        {
          name: "免责声明",
          icon: MImage,
          id: "statement",
          islogin: false,
        },
        {
          name: "隐私政策",
          icon: YImage,
          id: "privacy",
          islogin: false,
        },
        {
          name: "未成年保护",
          icon: CImage,
          id: "protect",
          islogin: false,
        }
      ],
      isMore: false, // 账号切换
      showPicker: false, //账号选择器
      columns: [],
      checked: true,
      checked_xieyi: true,
      cardInfo: [], // 卡信息
      card_Info: [],
      dayGetCoin: 0,
      coinSum: 0,
      wpay: WechatPayImage,
      alipay: AliPayImage,
      buymenu: false,
      radio: 'blue',
      cards: [
        {
          num: 500,
          txt: '5元',
          des: '充值5元=',
          total: 5,
        },
        {
          num: 1000,
          txt: '10元',
          des: '充值10元=',
          total: 10
        },
        {
          num: 5000,
          txt: '50元',
          des: '充值50元=',
          total: 50
        },
        {
          num: 10000,
          txt: '100元',
          des: '充值100元=',
          total: 100
        },
        {
          num: 20000,
          txt: '200元',
          des: '充值200元=',
          total: 200
        },
        {
          num: 50000,
          txt: '500元',
          des: '充值500元=',
          total: 500
        },
        {
          num: 100000,
          txt: '1000元',
          des: '充值1000元=',
          total: 1000
        },
        {
          num: 200000,
          txt: '2000元',
          des: '充值2000元=',
          total: 2000
        },
        {
          num: 300000,
          txt: '3000元',
          des: '充值3000元=',
          total: 3000
        }
      ],
      // 需要重置的属性
      currentSelect: 0,
      currentCard: 0,
      section: null,
      savemoney: null,
      params: 500,
      yueka: null,
      jika: null,
      nianka: null,
      xiaon: null,
      xinchun: null,
      // 
      isSignin: false,
      isHaveSign: "",
      duration: 0,
      Assists: {},
      newUserState: 0,
      openTip: false,
      numa: 0,
      p_Id: null,
      cardTip: {},
      isRealname: false,
      adopt: false,
      lockState: false
    };
  },
  computed: {
    ...mapGetters([
      "isLogin",
      "appid",
      "appkey",
      "time",
      "bundle",
      "CYBoxUser",
      "CYBoxLists",
      "CYBoxIDFA",
    ]),
  },
  mounted() {
    if (judgeBigScreen()) {
      if(this.$refs.myTop) this.$refs.myTop.className = "my_top myTopAndroid";
      if(this.$refs.myLeft1) this.$refs.myLeft1.className = "my_top_left myLeftAndroid";
      if(this.$refs.myLeft2) this.$refs.myLeft2.className = "my_top_left myLeftAndroid";
      var timer55 = setInterval(() => {
        if(this.$refs.myOut) {
          this.$refs.myOut.className = "my_out myLeftAndroid"
          clearInterval(timer55)
        }
      }, 10)
      var timer33 = setInterval(() => {
        if(this.$refs.myMore){
          this.$refs.myMore.className = "my_out myLeftAndroid";
          clearInterval(timer33)
        }
      }, 10)
    }
    this.onMore();
    if (this.CYBoxUser) {
      var params = {
        token: this.CYBoxUser.loginToken,
        time: Math.floor(Date.now() / 1000),
      };
      params.sign = ksort(params, "qsdhajasfas123sq");
      askUserinfo(params).then((res) => {
        if (res.code === 1) {
          this.p_Id = res.data.id
          this.pNum = res.data.phonenum ? res.data.phonenum : "未绑定"
        }
      });
      var realparam = {
        appid: this.appid,
        token: this.CYBoxUser.loginToken,
        currentDeviceIdfa: "",
        deviceId: this.CYBoxUser.deviceId
      }
      askRealName(realparam).then(realres => {
        if(realres.state == 1) {
          if(realres.message.card == 1) {
            // 已实名
            this.isRealname = true
          }
        }
      })
      // this.getCardData()
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.getCardData()
    })
  },
  methods: {
    ...mapActions([UPDATE_USER]),
    onSelect(item) {
      Dialog.confirm({
        title: '提示',
        message: '确认解除账号实名?',
      }).then(() => {
        var params = {
          token: this.CYBoxUser.loginToken,
          time: Math.floor(Date.now() / 1000),
          unbindingCardId: item.id
        }
        params.sign = ksort(params, 'qsdhajasfas123sq')
        askUncard(params).then(res => {
          if(res.code == 1) {
            var actions = this.actions
            var num = 0
            for(let i = 0; i < actions.length; i++) {
              if(actions[i].id == item.id) {
                num = i
              }
            }
            actions.splice(num,1)
            this.actions = actions
          }
        })
      }).catch(() => {
        
      });
    },
    getCardData() {
      if(this.CYBoxUser) {
        this.currentSelect = 0
        this.currentCard = 0
        this.savemoney = null
        this.section = null
        var lecoin = {
          userid: this.CYBoxUser.userID,
          token: this.CYBoxUser.loginToken,
          time: Math.floor(Date.now() / 1000)
        }
        lecoin.sign = ksort(lecoin, 'qsdhajasfas123sq')
        askLeCoin(lecoin).then(res => {
          var msg = res.message
          if(msg) {
            var cardArr = [], cardTip = {}
            msg.cardInfo.map(e => {
              if(e.type == '1') {
                cardArr.push(e)
              }
              if(e.id == '5') {
                this.Assists = e
              }
              if(e.type == '2' && e.id != '5') {
                cardTip = e
              }
            })
            this.cardInfo = cardArr
            this.card_Info = msg.cardInfo
            this.dayGetCoin = msg.dayGetCoin / 100
            this.coinSum = msg.coinSum
            if(msg.isPayUser == 0) {
              this.lockState = true
            }
            this.newUserState = msg.newUserState
            if(this.cardInfo[0].state != '0') {
              this.section = '有效日期' + timeApi(this.cardInfo[0].startTime) + '~' + timeApi(this.cardInfo[0].endTime) + ''
            } else {
              this.savemoney = this.cardInfo[0].saveMoney
            }
            this.cardTip = cardTip
            for(var i = 0; i < this.card_Info.length; i++) {
              if(this.card_Info[i].name == "月卡") {
                this.yueka = Number(this.card_Info[i].state)
              }
              if(this.card_Info[i].name == "季卡") {
                this.jika = Number(this.card_Info[i].state)
              }
              if(this.card_Info[i].name == "年卡") {
                this.nianka = Number(this.card_Info[i].state)
              }
              if(this.card_Info[i].name == "小年卡") {
                this.xiaon = Number(this.card_Info[i].state)
              }
              if(this.card_Info[i].name == "新春卡") {
                this.xinchun = Number(this.card_Info[i].state)
              }
            }
          }
        })
      }
    },
    onMore() {
      this.columns = [];
      if (this.CYBoxLists.length >= 1) {
        if (this.CYBoxUser) {
          this.isMore = true;
        }
        this.CYBoxLists.map((e, i) => {
          if(e) {
            this.columns.push({
              loginName: e.loginName,
              model: false,
              index: i
            });
          }
        });
      }
    },
    goCurPay() {
      this.buymenu = false
      store.commit('incrementIn')
      this.$router.push('/card')
    },
    gopay() {
      store.commit('incrementIn')
      this.$router.push('/card')
    },
    goplayed() {
      store.commit('incrementIn')
      // store.commit("tabbarOut");
      this.$router.push('/played')
    },
    goDaySign() {
      // 签到
      var params = {
        token: this.CYBoxUser.loginToken,
        time: Math.floor(Date.now() / 1000)
      }
      params.sign = ksort(params, 'qsdhajasfas123sq')
      askSignIndex(params).then(res => {
        if(res.code == 1) {
          var dataRes = res.data.data
          this.isSignin = true
          var len = dataRes.Duration.toString().length
          this.duration = dataRes.Duration.toString()[len - 1]
          if(dataRes.dayIsSign == 0) {
            // 可签到
            this.isHaveSign = "立即签到"
          } else {
            // 已签到
            this.isHaveSign = "已签到"
          }
        }
      })
    },
    goQian() {
      if(this.isHaveSign != '已签到') {
        var params = {
          token: this.CYBoxUser.loginToken,
          time: Math.floor(Date.now() / 1000)
        }
        params.sign = ksort(params, 'qsdhajasfas123sq')
        askDoSign(params).then(res => {
          if(res.code == 1) {
            this.isHaveSign = "已签到"
            this.getCardData()
            if(this.duration == 10) {
              this.duration = 1
            } else {
              this.duration++
            }
          } else {
            if(res.message == "实名绑定信息超过上限，账号异常") {
              askIncard(params).then(res => {
                if(res.code == 1) {
                  let ids = [], datas = []
                  for(let i = 0; i < res.data.length; i++) {
                    var id = res.data[i].id
                    if(ids.indexOf(id) == -1) {
                      ids.push(id)
                      datas.push({
                        id: res.data[i].id,
                        name: res.data[i].username
                      })
                    }
                  }
                  this.actions = datas
                  this.accountShow = true
                }
              })
            } else {
              Dialog.alert({
                message: res.message,
                theme: "round-button",
              }).then(() => {});
            }
          }
        })
      }
    },
    goInvitation() {
      store.commit('incrementIn')
      // store.commit("tabbarOut")
      this.$router.push('/invitation')
    },
    goNewUser() {
      store.commit('incrementIn')
      // store.commit("tabbarOut")
      this.$router.push('/vip')
    },
    goReward() {
      store.commit('incrementIn')
      // store.commit("tabbarOut");
      this.$router.push('/reward')
    },
    goCons() {
      store.commit('incrementIn')
      // store.commit("tabbarOut");
      this.$router.push('/lecoin')
    },
    goExplain() {
      this.admin.candy = true
    },
    lockClick() {
      this.admin.lock = true
    },
    goRecharge() {
      this.buymenu = true
    },
    selectPay(i) {
      this.currentSelect = i
      this.params = this.cards[i].num
    },
    selectCard(i) {
      this.section = null
      this.savemoney = null
      this.currentCard = i
      if(this.cardInfo[i].state == '0') {
        this.savemoney = this.cardInfo[i].saveMoney
      } else {
        this.section = '有效日期' + timeApi(this.cardInfo[i].startTime) + '~' + timeApi(this.cardInfo[i].endTime) + ''
      }
    },
    launchPay() {
      var params = {
        userid: this.CYBoxUser.userID,
        token: this.CYBoxUser.loginToken,
        lecoinId: this.params,
        orderType: this.radio,
        time: this.time,
        currentAppBuild: loadPageVar("bundle") || 'com.gamesbox'
      }
      params.sign = ksort(params, "qsdhajasfas123sq")
      askPayWith(params).then(res => {
        if(res.state == '1') {
          this.buymenu = false

          try {
            window.CYEventCenter.CYPay(res.message.url);
          } catch (error) {}
          try {
            window.webkit.messageHandlers.CYPay.postMessage(res.message.url);
          } catch (error) {}

          // window.open(res.message.url)

          setTimeout(() => {
            Dialog.confirm({
              confirmButtonText: "已支付",
              cancelButtonText: "已取消",
              message: '支付完成',
            }).then(() => {
              this.params = 500
              this.getCardData()
              //  刷新卡片信息
            }).catch(() => {
              this.params = 500
              this.getCardData()
              //  刷新卡片信息
            })
          }, 500)
        }
      })
      // 

      // var params = {
      //   time: this.time,
      //   token: this.CYBoxUser.loginToken,
      //   appid: this.appid,
      //   productId: 'lecoin_' + this.params,
      //   CPsendInfo: e.info,
      //   appkey: e.appkey,
      //   currentAppBuild: e.currentAppBuild,
      //   id: v.appId
      // }
      // params2.sign = ksort(params2, "wxjsmnbc#784234#letang")
    },
    createClick(numa) {
      this.openTip = true
      this.numa = numa
      var params = {
        userid: this.CYBoxUser.userID,
        token: this.CYBoxUser.loginToken,
        time: Math.floor(Date.now() / 1000)
      }
      params.sign = ksort(params, "qsdhajasfas123sq")
      askRecived(params).then(res => {
        this.getCardData()
      })
    },
    onConfirm(value) {
      this.CYBoxLists.map((e, i) => {
        if(e) {
          if (e.loginName === value.loginName) {
            var params = {
              appid: this.appid,
              username: e.loginName,
              password: e.password.length < 13 ? e.password : Decrypt(e.password),
              time: Math.floor(Date.now() / 1000),
              eventType: 1,
              currentAppBuild: this.bundle,
              currentDeviceIdfa: "",
              deviceId: this.CYBoxIDFA,
            };
            var isKefu = localStorage.getItem('isKefu')
            if(isKefu) params.isKefu = localStorage.getItem('isKefu')
            params.sign = ksort(params, this.appkey);
            askLogin(params).then((res) => {
              if (res.state === "1") {
                var temp = res.message;
                temp.password =
                  e.password.length < 13 ? Encrypt(e.password) : e.password;
                this.UPDATE_USER(temp);
                store.commit("loginIn");
                if (this.checked) {
                  store.commit("CYBoxLists", temp);
                }
                this.onMore();
                this.admin.login = false;
                this.getphone();
                this.form = this.$options.data().form;
                this.getCardData()
              }
            });
          }
        }
      });
      this.showPicker = false;
    },
    onclick(id) {
      if(id == 'auth') {
       if(this.isRealname) {
          Dialog.alert({
            title: '提示',
            message: '您已完成实名认证',
          })
        } else {
          this.admin[id] = true;
        }
      } else {
        this.admin[id] = true;
      }
      switch (id) {
        case "agreement":
          askAgreement().then((res) => {
            this.agreementList = res;
          });
          break;
        case "permit":
          askPermit().then((res) => {
            this.permitList = res;
          });
          break;
        case "privacy":
          askPrivacy().then((res) => {
            this.privacyList = res;
          });
          break;
        case "protect":
          askProtect().then((res) => {
            this.protectList = res;
          });
          break;
        case "statement":
          askStatement().then((res) => {
            this.statementList = res;
          });
          break;
        default:
          break;
      }
    },
    clickIcon(e) {
      this.ps[e] = "text";
    },
    getphone() {
      if (this.CYBoxUser) {
        var params = {
          token: this.CYBoxUser.loginToken,
          time: Math.floor(Date.now() / 1000),
        };
        params.sign = ksort(params, "qsdhajasfas123sq");
        askUserinfo(params).then((res) => {
          if (res.code === 1) {
            this.pNum = res.data.phonenum ? res.data.phonenum : "未绑定";
          }
        });
      }
    },
    clickHandle(handle, transmit) {
      switch (handle) {
        case "login":
          if(this.checked_xieyi) {
            var params = {
              appid: this.appid,
              username: this.form.username,
              password: this.form.password,
              time: Math.floor(Date.now() / 1000),
              eventType: 1,
              currentAppBuild: this.bundle,
              currentDeviceIdfa: "",
              deviceId: this.CYBoxIDFA,
            };
            var isKefu = localStorage.getItem('isKefu')
            if(isKefu) params.isKefu = localStorage.getItem('isKefu')
            params.sign = ksort(params, this.appkey);
            askLogin(params).then((res) => {
              if (res.state === "1") {
                var temp = res.message;
                temp.password = Encrypt(this.form.password);
                this.UPDATE_USER(temp);
                store.commit("loginIn");
                if (this.checked) {
                  store.commit("CYBoxLists", temp);
                }
                this.onMore();
                this.admin.login = false;
                this.getphone();
                this.form = this.$options.data().form;
                this.getCardData()
              } else {
                Dialog.alert({
                  message: res.message,
                  theme: "round-button",
                }).then(() => {});
              }
            });
          } else {
            Dialog.alert({
              message: "需要同意用户协议",
              theme: "round-button",
            }).then(() => {});
          }
          break;
        case "modifyusername":
          var params = {
            appid: this.appid,
            username: this.CYBoxUser.loginName,
            password: this.form.password,
            newName: this.form.username,
            time: Math.floor(Date.now() / 1000),
            currentAppBuild: this.bundle,
            currentDeviceIdfa: "",
            deviceId: this.CYBoxIDFA,
          };
          params.sign = ksort(params, this.appkey);
          askSaveName(params).then((res) => {
            if (res.state === "1") {
              var temp = res.message;
              temp.password = Encrypt(res.message.password);
              store.commit("listUpdate", temp);
              store.commit("userUpdate", temp);
              this.admin.username = false;
              Toast.success("修改成功");
              this.form = this.$options.data().form;
            } else {
              Dialog.alert({
                message: res.message,
                theme: "round-button",
              }).then(() => {});
            }
          });
          break;
        case "modifypassword":
          if (this.form.newpassword === this.form.confirmpassword) {
            var params = {
              appid: this.appid,
              username: this.CYBoxUser.loginName,
              password: this.form.password,
              newPassword: this.form.newpassword,
              time: Math.floor(Date.now() / 1000),
              currentAppBuild: this.bundle,
              currentDeviceIdfa: "",
              deviceId: this.CYBoxIDFA,
            };
            params.sign = ksort(params, this.appkey);
            askSavePass(params).then((res) => {
              if (res.state === "1") {
                var temp = res.message;
                temp.password = Encrypt(res.message.password);
                store.commit("listUpdate", temp);
                store.commit("userUpdate", temp);
                this.admin.password = false;
                Toast.success("修改成功");
                this.form = this.$options.data().form;
              } else {
                Dialog.alert({
                  message: res.message,
                  theme: "round-button",
                }).then(() => {});
              }
            });
          } else {
            Dialog.alert({
              message: "两次密码输入不一致",
              theme: "round-button",
            }).then(() => {});
          }
          break;
        case "bindphone":
          var params = {
            appid: this.appid,
            username: this.CYBoxUser.loginName,
            password: this.form.password,
            code: this.form.code,
            newPhoneNum: this.form.phone,
            time: Math.floor(Date.now() / 1000),
            currentAppBuild: this.bundle,
            currentDeviceIdfa: "",
            deviceId: this.CYBoxIDFA,
          };
          params.sign = ksort(params, this.appkey);
          askSavePhone(params).then((res) => {
            if (res.state === "1") {
              var temp = res.message;
              temp.password = Encrypt(res.message.password);
              store.commit("listUpdate", temp);
              store.commit("userUpdate", temp);
              this.admin.bind = false;
              Toast.success("绑定成功");
              this.form = this.$options.data().form;
              // ============= 获取绑定手机号
              this.getphone();
              // =============
            } else {
              Dialog.alert({
                message: res.message,
                theme: "round-button",
              }).then(() => {});
            }
          });
          break;
        case "bindphone_up":
          var params = {
            appid: this.appid,
            username: this.CYBoxUser.loginName,
            password: this.form.password,
            code: this.form.code,
            newPhoneNum: this.form.phone,
            time: Math.floor(Date.now() / 1000),
            currentAppBuild: this.bundle,
            currentDeviceIdfa: "",
            deviceId: this.CYBoxIDFA,
          };
          params.sign = ksort(params, this.appkey);
          askSavePhone(params).then((res) => {
            if (res.state === "1") {
              var temp = res.message;
              temp.password = Encrypt(res.message.password);
              store.commit("listUpdate", temp);
              store.commit("userUpdate", temp);
              this.admin.bind = false;
              Toast.success("绑定成功");
              this.form = this.$options.data().form;
              // ============= 获取绑定手机号
              this.getphone();
              this.admin.unbind = false
              // =============
            } else {
              Dialog.alert({
                message: res.message,
                theme: "round-button",
              }).then(() => {});
            }
          });
          break;
        case "updatebindphone":
          var params = {
            appid: this.appid,
            time: Math.floor(Date.now() / 1000),
            phonenum: this.form.cphone,
            code: this.form.ccode,
          };
          params.sign = ksort(params, this.appkey);
          askconfirmPhone(params).then(res => {
            if(res.state == 1) {
              this.adopt = true
            }
          })
          break;
        case "getcode":
          var params = {
            appid: this.appid,
            phonenum: this.form.phone,
            time: Math.floor(Date.now() / 1000),
            currentAppBuild: this.bundle,
            currentDeviceIdfa: "",
            deviceId: this.CYBoxIDFA,
          };
          params.sign = ksort(params, this.appkey);
          askGetCode(params).then((res) => {
            if (res.state === "1") {
              var i = Number(res.message);
              Toast("验证码已发送, 请查收");
              var timer = setInterval(() => {
                if (i == 0) {
                  this.$refs[transmit].style.cssText +=
                    "background:linear-gradient(90deg, #ff5659 35%, #ff0103 100%);pointer-events: auto;";
                  this.$refs[transmit].innerHTML = "获取验证码";
                  clearInterval(timer);
                } else {
                  this.$refs[transmit].style.cssText +=
                    "background:#666;pointer-events: none;";
                  this.$refs[transmit].innerHTML = i;
                }
                i--;
              }, 1000);
            } else {
              Dialog.alert({
                message: res.message,
                theme: "round-button",
              }).then(() => {});
            }
          });
          break;
        case "getccode":
          var params = {
            appid: this.appid,
            phonenum: this.form.cphone,
            time: Math.floor(Date.now() / 1000),
            currentAppBuild: this.bundle,
            currentDeviceIdfa: "",
            deviceId: this.CYBoxIDFA,
          };
          params.sign = ksort(params, this.appkey);
          askGetCode(params).then((res) => {
            if (res.state === "1") {
              var i = Number(res.message);
              Toast("验证码已发送, 请查收");
              var timer = setInterval(() => {
                if (i == 0) {
                  this.$refs[transmit].style.cssText +=
                    "background:linear-gradient(90deg, #ff5659 35%, #ff0103 100%);pointer-events: auto;";
                  this.$refs[transmit].innerHTML = "获取验证码";
                  clearInterval(timer);
                } else {
                  this.$refs[transmit].style.cssText +=
                    "background:#666;pointer-events: none;";
                  this.$refs[transmit].innerHTML = i;
                }
                i--;
              }, 1000);
            } else {
              Dialog.alert({
                message: res.message,
                theme: "round-button",
              }).then(() => {});
            }
          });
          break;
        case "unbindphone":
          var params = {
            appid: this.appid,
            phonenum: this.form.phone,
            code: this.form.code,
            time: Math.floor(Date.now() / 1000),
            currentDeviceIdfa: "",
            deviceId: this.CYBoxIDFA,
          };
          params.sign = ksort(params, this.appkey);
          askunBindPhone(params).then((res) => {
            if (res.state === "1") {
              this.admin.unbind = false;
              Toast.success("解绑成功");
              // 刷新绑定手机界面
              this.form = this.$options.data().form;
              // ==================================
              this.getphone();
              // ==================================
            } else {
              Dialog.alert({
                message: res.message,
                theme: "round-button",
              }).then(() => {});
            }
          });
          break;
        case "auth":
          var params = {
            token: this.CYBoxUser.loginToken,
            name: this.form.name.trim(),
            number: this.form.idcard.trim(),
            currentDeviceIdfa: "",
            deviceId: this.CYBoxIDFA,
          };
          params.sign = ksort(params, this.appkey);
          let vidcard = this.form.idcard
          let vname = this.form.name
          if(validate(vidcard)) {
            var ctime = Math.floor(Date.now() / 1000)
            var adulttime = (birthDate(vidcard) - 0) / 1000 + 31536000 * 18
            if(ctime > adulttime) {
              if(vname.trim().length < 2) {
                Toast("请输入姓名");
              } else {
                askBindCard(params).then(res => {
                  if (res.state === "1") {
                    Toast.success("实名认证成功");
                    this.admin.auth = false;
                    this.form = this.$options.data().form;
                  } else {
                    Dialog.alert({
                      message: res.message,
                      theme: "round-button",
                    }).then(() => {});
                  }
                });
              }
            } else {
              Dialog.alert({
                message: '检测到您的年龄为未成年, 禁止登录游戏',
                theme: "round-button",
              }).then(() => {});
            }
          } else {
            Toast("请输入正确的身份证号");
          }
          break;
        case "findpassword":
          if (this.form.password === this.form.confirmpassword) {
            var params = {
              appid: this.appid,
              phonenum: this.form.phone,
              code: this.form.code,
              password: this.form.password,
              time: Math.floor(Date.now() / 1000),
              currentDeviceIdfa: "",
              deviceId: this.CYBoxIDFA,
            };
            params.sign = ksort(params, this.appkey);
            askFindPass(params).then((res) => {
              if (res.state === "1") {
                Toast.success("密码已重置");
                this.admin.retrieve = false;
                this.form = this.$options.data().form;
              } else {
                Dialog.alert({
                  message: res.message,
                  theme: "round-button",
                }).then(() => {});
              }
            });
          } else {
            Dialog.alert({
              message: "两次密码输入不一致",
              theme: "round-button",
            }).then(() => {});
          }
          break;
        case "phoneregister":
          if(this.checked_xieyi) {
            var params = {
              appid: this.appid,
              phonenum: this.form.phone,
              code: this.form.code,
              time: Math.floor(Date.now() / 1000),
              eventType: 2,
              currentAppBuild: this.bundle,
              currentDeviceIdfa: "",
              deviceId: this.CYBoxIDFA,
            };
            var isKefu = localStorage.getItem('isKefu')
            if(isKefu) params.isKefu = localStorage.getItem('isKefu')
            params.sign = ksort(params, this.appkey);
            askPhoneRegister(params).then((res) => {
              if (res.state === "1") {
                var temp = res.message;
                temp.password = Encrypt(res.message.password);
                this.UPDATE_USER(temp);
                store.commit("loginIn");
                if (this.checked) {
                  store.commit("CYBoxLists", temp);
                }
                this.onMore();
                this.admin.register = false;
                this.admin.login = false;
                Toast("登录成功");
                this.getphone();
                Dialog.alert({
                  title: "您的初始密码, 请妥善保管",
                  message: Decrypt(res.message.password),
                  theme: "round-button",
                }).then(() => {});
                this.form = this.$options.data().form;
                this.getCardData()
              } else {
                Dialog.alert({
                  message: res.message,
                  theme: "round-button",
                }).then(() => {});
              }
            });
          } else {
            Dialog.alert({
              message: "需要同意用户协议",
              theme: "round-button",
            }).then(() => {});
          }
          break;
        case "usernameregister":
          if(this.checked_xieyi) {
            var params = {
              appid: this.appid,
              username: this.form.username,
              password: this.form.password,
              time: Math.floor(Date.now() / 1000),
              eventType: 2,
              currentAppBuild: this.bundle,
              currentDeviceIdfa: "",
              deviceId: this.CYBoxIDFA,
            };
            var isKefu = localStorage.getItem('isKefu')
            if(isKefu) params.isKefu = localStorage.getItem('isKefu')
            params.sign = ksort(params, this.appkey);
            askUserNameRegister(params).then((res) => {
              if (res.state === "1") {
                var temp = res.message;
                temp.password = Encrypt(this.form.password);
                this.UPDATE_USER(temp);
                store.commit("loginIn");
                if (this.checked) {
                  store.commit("CYBoxLists", temp);
                }
                this.onMore();
                this.admin.register = false;
                this.admin.login = false;
                this.admin.newuser = false;
                Toast("登录成功");
                this.getphone();
                this.form = this.$options.data().form;
                this.getCardData()
              } else {
                Dialog.alert({
                  message: res.message,
                  theme: "round-button",
                }).then(() => {});
              }
            });
          } else {
            Dialog.alert({
              message: "需要同意用户协议",
              theme: "round-button",
            }).then(() => {});
          }
          break;
        case "signout":
          store.commit("loginOut");
          this.coinSum = 0
          this.currentSelect = 0
          this.currentCard = 0
          this.section = null
          this.savemoney = null
          this.isMore = false;
          break;
        case "switch":
          this.showPicker = true;
          break;
        default:
          break;
      }
    },
    closeTip() {
      this.openTip = false
    },
    pickerCancel() {
      if(this.cancelButtonText == "管理") {
        this.cancelButtonText = "取消"
        this.setUserList = true
      } else if(this.cancelButtonText == "删除") {
        Dialog.confirm({
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          message: '确认删除账号?',
        }).then(() => {
          store.commit("listDelete", this.columns);
          this.cancelButtonText = "管理"
          this.onMore();
        }).catch(() => {
          
        })
      } else if(this.cancelButtonText == "取消") {
        this.setUserList = false
        this.cancelButtonText = "管理"
      }
    },
    pickerChange(e) {
      this.columns[e.index].model = e.model
      let num = 0
      for(let i = 0; i < this.columns.length; i++) {
        if(this.columns[i].model) {
          num++
        }
      }
      if(num > 0) {
        this.cancelButtonText = "删除"
      } else {
        this.cancelButtonText = "取消"
      }
    },
    isAgreement() {
      this.admin.agreement = true
      askAgreement().then((res) => {
        this.agreementList = res;
      });
    }
  },
};
</script>

<style scoped lang="stylus">
@import '../components/common.styl';

.my_top {
  background: url('../assets/playedt.png') no-repeat center;
  background-size: 100% 100%;
  height: calc(98px + constant(safe-area-inset-bottom));
  height: calc(98px + env(safe-area-inset-bottom));
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  position: relative;
}

.my_top_left {
  display: flex;
}

.my_out {
  font-size: 14px;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 0 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.my_top_left, .my_out {
  margin-top: constant(safe-area-inset-bottom);
  margin-top: env(safe-area-inset-bottom);
}

.my_top_image {
  height: 46px;
  width: 46px;
  border-radius: 50%;
  margin-right: 14px;
}

.my_vip
  height 14px
  width 40px
  margin-right 1px
  margin-left 1px

.my_top_text {
  color: #fff;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.my_center {
  margin: 0 auto;
  // height: 390px;
  width: $viewWidth;
  background: $themeWhite;
  border-radius: 5px;
  font-size: 14px;
  position: relative;
  top: -18px;
}
.my_balance {
  width: 100%
  position: relative;
  overflow: hidden;
}

.my_balance_content {
  height 128px
  text-align center
  width 100%
  // background-image: url(../assets/ye.png)
  // background-repeat: no-repeat
  // background-size 92% 100%
  // background-origin center
  margin-top 24px
  // position: relative;
}
.my_balance_bg
  width 94%
  height 130px

.my_balance_txt
  font-size 14px
  color #9d6820
  padding-top 48px
  padding-left 8%
  display: flex;
  align-items: center;
  position absolute
  top 0
  left 0
  min-width 200px
.pay_btn
  position absolute
  top 80px 
  left 8%
  background:linear-gradient(90deg,#c67b18 30%,#926643 100%);
  width 50px
  height 20px
  line-height 20px
  border-radius 10px
.pay_txt
  font-size 14px
  color: #ffd584;
.my_balance_w
  margin-left 2px
  margin-right 2px
  height 16px
  width 16px
.my_balance_ye
  font-size 22px 
  color: #c67b18;
  background:-webkit-linear-gradient(0deg,#c67b18 50%,#926643 100%);
  -webkit-background-clip:text;
  -webkit-text-fill-color:transparent;
  padding-left 2px
.my_card_s
  position: absolute;
  top 6px
  right 4%
  display flex
  margin-top 24px
.my_card_rmb
  font-size 14px
  color #9d6820
  position: absolute;
  right: 8%;
  top: 30px;
  margin-top 30px
  display: flex
  flex-direction: column;
.my_txt_time
  margin-bottom 4px
  font-size 18px
.my_txt_des
  font-size 14px
  margin-top: 12px;
  width 154px
.my_txt_savemoney 
  font-size 16px
.my_txt_big
  font-size 24px
.my_card_banner
  height 22px
  line-height 22px
  width 122px
  font-size 12px
  background #f4deb3
  color #9d6820
  padding-left 8px
  border-radius 11px 0 0 11px
  margin-right: 1px;
  position: absolute;
  right: 3%;
  top 62px
  margin-top 30px
.my_card_l
  width 138px
  height 20px
  text-align center
  border-radius 8px
  background #f5dfb5
  bottom: 6px;
  position: absolute;
  left: 50%;
  margin-left: -64px;
.my_card_txt
  color: #c67b18;
  background:-webkit-linear-gradient(0deg,#c67b18 50%,#926643 100%);
  -webkit-background-clip:text;
  -webkit-text-fill-color:transparent;
.my_card_tmbtxt
  font-size 22px
  background:-webkit-linear-gradient(0deg,#c67b18 50%,#926643 100%);
  -webkit-background-clip:text;
  -webkit-text-fill-color:transparent;
.my_cardview
  // width 38px
  height 20px
  background:linear-gradient(180deg,#fdc57d 50%,#d9975f 100%);
  color #9d6820
  border-radius 4px
  display flex
  justify-content: center;
  align-items: center;
  margin-right: 4px;
.my_balance_card
  // width 34px
  height 18px
  font-size 12px
  line-height 18px
  text-align center
  border 1px solid #ffe8b7
  border-radius 2px
  margin 2px
  padding: 0 2px;
.my_center_top {
  display: flex;
  // justify-content: space-between;
  padding-top: 10px;
  margin: 0 auto;
  width 96%
}
.my_played {
  width 30%
  height 98px
  background #f8f8f8
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  border-radius 5px
  font-size 16px;
  justify-content: center;
  margin 0 1.6%
}
.my_played_img {
  width: 34px
  height 34px
  margin-left 20px
  margin-right 14px
}
.my_played_txt {
  margin-top 6px
}
.my_played_r {
  width: 12px
  height: 12px;
  position: absolute;
  margin-top -6px;
  top: 50%
  right: 15px
}

.my_center_btm {
  margin: 0 auto;
  width: 100%;
  background: $themeWhite;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 12px;
  justify-content: space-between;
  margin-bottom: 40px;
}

.my_center_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 6px -2px;
  width: 80px;
  span {
    font-size 12px
  }
}

.my_center_icon {
  height: 42px;
  width: 42px;
}

.my_center_bg {
  // background: linear-gradient(90deg, #ff1523 50%, #ff6149 100%);
  width: 42px;
  height: 42px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 6px;
}


</style>

<style lang="stylus" scoped>
@import '../components/common.styl';

.my_remarks {
  font-size: 12px;
  color: $themeColor;
  padding-left: 10px;
  padding-top: 4px;
}

.my_txt {
  font-size: 14px;
  padding: 1px 16px;
  background: #f2f2f6;
  letter-spacing: 0.5px;
  line-height: 20px;
}

.my_click {
  width: 84px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 15px;
  font-size: 14px;
}

.card_top
  // height 200px
  background #fff
  border-radius 4px
  margin-bottom 10px
  width 96%
  margin 0 auto 10px
  color #5d5a5a
  display flex
  flex-wrap: wrap;
  align-items: center;
  padding: 6px 0;
  justify-content: space-evenly;
.card_fuhao
  font-size 20px
.card_je
  font-size 34px
.card_center
  border-radius 4px
  background #fff
  // height 196px
  width 96%
  margin 0 auto
.card_fs
  font-size 18px
  text-align center
  padding-top 16px
  padding-bottom 28px
.card_select
  margin 0 auto
  width 80%

.card_card {
  font-size: 0;
  height: 130px;
  margin-bottom: 10px;
}

.card_title {
  font-size: 16px;
  margin-bottom: 6px;
}

.card_txt {
  font-size: 12px;
  text-align: left;
  width: 96%;
  margin: 0 auto;
  padding-bottom: 10px;
}

.card_text {
  margin-bottom: 4px;
  letter-spacing: 2px;
  line-height: 18px;
}

.card_i
  height 72px
  width 30%
  background #f6f6f6
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius 2px
  margin: 4px 0;
.card_count
  font-size 16px
  margin-bottom: 4px;
.card_des
  font-size 9px 
.page_pay
  width: 188px
  height: 46px;
  border-radius 23px;
  font-size 18px
  background: #20a0ff;
  background:linear-gradient(90deg,#ff5555 50%,#ff0000 100%);
  color #fff
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 28px auto 0;
.card_tip
  position fixed
  top 0
  left 0
  right 0
  bottom 0
  width 100%
  height 100%
  background rgba(0,0,0,.8)
  z-index: 10;
.card_tip_image
  width: 310px;
  position: absolute;
  left: 50%;
  margin-left: -155px;
  top: 20%;
.card_tip_text
  color: #ffea97;
  font-size: 32px;
  font-weight: 600;
  position: absolute;
  top: 20%;
  left: 50%;
  text-shadow: 1px 2px 1px rgba(0,0,0,0.5);
  margin-top: 176px;
  margin-left: -52px;
  // transform: rotate(-2deg);
.my_lock
  height 18px
  width 18px
  margin-left 5px
</style>